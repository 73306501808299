//import Select from 'components/templates/form/Select';
import React from "react";
import Select from 'components/templates/form/Select';
import { navigate } from "gatsby";

const PressSelect = (props) => {

	const { isClippingPage } = props;

	let typeOptions = [
		{ value: '/press/', label: 'Press Release' },
		{ value: '/clippings/', label: 'Press Clipping' }
	]

	if (isClippingPage) typeOptions = typeOptions.reverse();

	const handleTypeChange = (e) => {
		navigate(e.target.value);
	}
	
	return (
		<div className="filter-wrapper">
			<div className="grid-container">
				<div className="grid-x grid-padding-x align-center">
					<div className="medium-11 small-10 cell">
						<div className="filter-label">Discover By Type</div>
						<div className="flx-wrapper">
							<div className="flx-item">
								<Select onChange={handleTypeChange} label="Type" options={typeOptions} placeholder="Type" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PressSelect;