import React, { Component } from 'react';
import * as style from 'styles/form/select.module.scss';

export default class Select extends Component {

	constructor(props) {
		super(props);
		this.selectInput = React.createRef();
	}
	render() {
		if (this.props.focusState) {
			this.selectInput.current.focus({preventScroll: true});
		}
		let name = (this.props.field && this.props.field.name ? this.props.field.name : this.props.name);
		let value = (this.props.field && this.props.field.value ? this.props.field.value : this.props.value);
		let onChange = (this.props.field && this.props.field.onChange ? this.props.field.onChange : this.props.onChange);
		let onBlur = (this.props.field && this.props.field.onBlur ? this.props.field.onBlur : this.props.onBlur);
		let label = this.props.label;
		let errorCondition = (this.props.form && this.props.form.touched[name] && this.props.form.errors[name] ? true : (this.props.errorCondition !== undefined ? this.props.errorCondition : false));
		let errorMessage = (this.props.form && this.props.form.errors[name] ? this.props.form.errors[name] : this.props.errorMessage);
		let autoComplete = (this.props.autoComplete !== undefined ? (this.props.autoComplete === 'off' || this.props.autoComplete === false ? 'off' : 'on') : 'on');
		let options = [];
		let hideLabel = (this.props.hideLabel !== undefined ? this.props.hideLabel : false);
		let delimiter = (this.props.delimiter !== undefined ? this.props.delimiter : ',');
		if (value === undefined && this.props.default !== undefined)value = this.props.default;
		if (this.props.options) {
			if (Array.isArray(this.props.options)) {
				for (let option of this.props.options) {
					if (typeof option === 'object') {
						options.push({
							value: option.value,
							label: option.label||option.text,
							selected: option.selected||false,
						})
					}
					else {
						options.push({
							value: option,
							label: option,
							selected: (option === value),
						})
					}
				}
			}
			if (typeof this.props.options === 'string') {
				for (let option of this.props.options.split(delimiter)) {
					options.push({
						value: option,
						label: option,
						selected: (option === value),
					})
				}
			}
		}
		return (
			<div className={`${style.container} ${this.props.containerClassName}`}>
				{!hideLabel && <label htmlFor={name} className={style.label}>{label}</label> }
				<div className={style.fieldContainer}>
					<select aria-label={label} autoComplete={autoComplete} className={`${style.inputField}`} id={name} ref={this.selectInput} placeholder={this.props.placeholder} name={name} value={value} onChange={onChange} onBlur={onBlur}>
						<option value="" disabled>{this.props.placeholder}</option>
						{ options.map((item, i) => {
							return (
								<option key={item.value} value={item.value}>{item.label}</option>
							)
						}) }
					</select>
					<div className={ [style.error, (errorCondition ? style.active : '')].join(' ') }>{errorMessage}</div>
				</div>
			</div>
		)
	}
}