import * as React from "react"
import { graphql } from 'gatsby';

import NotFound from 'pages/404';
import Layout from 'templates/Layout';
import Content from 'templates/Content';
import Link from 'components/Link'

import Pager from 'global/Pager';

import DataProvider from 'providers/data';
import PressSelect from "components/templates/global/PressSelect";


const PressClippingAll = (props) => {
	const { edges: pageEdges } = props.data.allContentPage;
	const { edges: nodes } = props.data.allPressClipping;
	let clippingsArr = nodes.map(({ node }) => node);
	const pageNodes = pageEdges.map(({ node }) => node);
	let page = pageNodes[0];
	let { pageInfo } = props.data.allPressClipping;
	const { pageContext } = props;

	if ((!!props.providerResponse !== false && props.providerResponse.data.length > 0) && props.providerStatusText === 'LOADED' && pageContext.liveRefresh !== false) {
		clippingsArr = props.providerResponse.data;
		pageInfo = {
			hasPreviousPage: (props.providerResponse.page > 1),
			hasNextPage: (props.providerResponse.page < props.providerResponse.pages),
			perPage: props.providerResponse.pageSize,
			currentPage: props.providerResponse.page,
			totalCount: props.providerResponse.total,
			pageCount: props.providerResponse.pages,
		};
	}

	const pattern = ['/clippings/', '/clippings/page-$page/'];
	const [clippings, setClippings] = React.useState(clippingsArr)

	React.useEffect(() => {
		if (props.providerStatusText === "LOADED") {
			setClippings(clippingsArr)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.providerStatusText])

	if (pageInfo.currentPage > pageInfo.pageCount && (props.providerStatusText === 'LOADED' || pageContext.liveRefresh === false)) {
		return <NotFound />
	}

	return (
		<>
			<Layout>
				<Content {...page} />
				<PressSelect isClippingPage={true} />
				<section id="clippings">
					<div className="grid-container">
						<div className="grid-x grid-margin-x small-up-1 medium-up-2 large-up-3">
							{clippings.length > 0 && clippings.map(clipping => {
								const card = {
									title: clipping.title,
									body: clipping.excerpt,
									source: clipping.sourceOutlet,
									link: clipping.sourceURL ? clipping.sourceURL : '',
									linkText: 'Read More',
								}
								return (
									<div key={clipping.id} className={`cell`} style={{ marginBottom: '27px' }}>
										<div className="card">
											{card.link ? (
											<Link to={card.link}>
												<h5>{card.title}</h5>
												{card.source && <small>{card.source}</small>}
												{card.body && <p>{card.body}</p>}
												<div className="button no-hover">{card.linkText}</div>
											</Link>
											) : (
												<>
													<h5>{card.title}</h5>
													{card.source && <small>{card.source}</small>}
													{card.body && <p style={{ color: "#056791" }}>{card.body}</p>}
												</>
											)}
										</div>
									</div>
								)
							})}
							{clippings.length === 0 && <div className="cell">No clippings found.</div>}
						</div>
					</div>
					<div className="grid-container">
						<div className="grid-x grid-margin-x">
							<div className="cell small-12">
								{(pageInfo.pageCount > 1) && <Pager page={pageInfo.currentPage} pages={pageInfo.pageCount} pattern={pattern} pageSize={pageInfo.perPage} total={pageInfo.totalCount} />}
							</div>
						</div>
					</div>
				</section>
			</Layout>
		</>
	)
}

// export default PressClippingAll
const getData = (props) => <DataProvider liveRefresh={props.pageContext.liveRefresh} type="PressClipping" apiParams={{ _page: props.pageContext.page, _pageSize: props.pageContext.limit, _sort: "showDate,title", _order: "desc" }}><PressClippingAll {...props} /></DataProvider>
export default getData;

export { Head } from 'components/templates/Head';

export const query = graphql`
query ($skip: Int!, $limit: Int!) {
	allContentPage(
	  filter: {uri: {eq: "/clippings/"}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}
	) {
	  edges {
		node {
		  pageTitle
		  meta
		  content {
			main
		  }
		  headlineBody
		  headlineTitle
		  headlineImage
		  headlineType
		}
	  }
	}
	allPressClipping(
	  filter: {approved: {eq: true}, archived: {eq: false}, deleted: {eq: false}}
	  sort: {order: [DESC, DESC], fields: [showDate, title]}
	  limit: $limit
	  skip: $skip
	) {
	  edges {
		node {
		  id
		  title
		  excerpt
		  sourceOutlet
		  sourceURL
		  showDate
		}
	  }
	  pageInfo {
		hasNextPage
		hasPreviousPage
		perPage
		currentPage
		totalCount
		pageCount
	  }
	}
  }
`